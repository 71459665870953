import { Module } from 'vuex';
import firebase from 'firebase';
import { RootState, WhatsappGroup, WhatsappGroupsState } from '../types';

const FIREBASE_COLL = process.env.VUE_APP_WHATSAPP_GROUPS;

const whatsappGroupsModule: Module<WhatsappGroupsState, RootState> = {
  namespaced: true,
  state: {
    groups: [],
  },

  mutations: {
    setChannels(state: WhatsappGroupsState, payload: WhatsappGroup[]) {
      state.groups = payload;
    },
  },

  actions: {
    fetch({ commit }, payload) {
      const colRef = firebase.firestore().collection(FIREBASE_COLL);
      colRef.where('uid', '==', payload.uid)
        .get()
        .then((data) => {
          const channels: any[] = [];
          data.forEach((channel) => {
            channels.push(channel.data());
          });
          commit('setChannels', channels);
        });
    },

    create({ dispatch }, payload: WhatsappGroup) {
      this.dispatch('app/showIsLoading');
      const colRef = firebase.firestore().collection(FIREBASE_COLL);
      payload.id = colRef.doc().id;

      colRef
        .doc(payload.id)
        .set(payload)
        .then(() => {
          this.dispatch('app/showIsSuccess');
          dispatch('fetch', { uid: payload.uid });
        })
        .catch(() => {
          this.dispatch('app/showIsError');
        });
    },

    update({ dispatch }, payload: WhatsappGroup) {
      this.dispatch('app/showIsLoading');
      const colRef = firebase.firestore().collection(FIREBASE_COLL);
      colRef
        .doc(payload.id)
        .update(payload)
        .then(() => {
          this.dispatch('app/showIsSuccess');
          dispatch('fetch', { uid: payload.uid });
        })
        .catch(() => {
          this.dispatch('app/showIsError');
        });
    },

    delete({ dispatch }, payload: WhatsappGroup) {
      this.dispatch('app/showIsLoading');
      const colRef = firebase.firestore().collection(FIREBASE_COLL);
      colRef
        .doc(payload.id)
        .delete()
        .then(() => {
          this.dispatch('app/showIsSuccess');
          dispatch('fetch');
        })
        .catch(() => {
          this.dispatch('app/showIsError');
        });
    },
  },

  getters: {
    getGroups(state: WhatsappGroupsState): WhatsappGroup[] {
      return state.groups;
    },
  },
};

export default whatsappGroupsModule;
