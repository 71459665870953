
import Vue from "vue";
import { mapGetters } from "vuex";
import Loading from "./views/LoadingPage.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue"
import Cookies from "js-cookie/index";

export default Vue.extend({
  name: "App",

  components: {
    Loading,
    Login,
    Register
  },

  data: () => ({
    drawer: false,
    group: null,
    toRegister: false,
    deferredPrompt: null as any,
    routes: [
      { name: 'Página Inicial', icon: "mdi-home", link: '/home', roles: [] },
      { name: 'Meus Grupos', icon: "mdi-message-fast", link: '/groups', roles: [] },
      { name: 'Meu Perfil', icon: "mdi-account-multiple", link: '/profile', roles: [] },
    ]
  }),

  computed: {
    ...mapGetters({
      alerts: "app/getAlerts",
      loading: "auth/getLoading",
      user: "auth/getUser"
    })
  },

  watch: {
    user() {
      if (!this.$route.fullPath.includes('home') && !this.user.approved) {
        this.goTo('home');
      }
    }
  },

  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },

  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", '', { expires: 15 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    goTo(path: string) {
      this.$router.push(path)
    },
    exit() {
      this.$store.dispatch('auth/logout')
    }
  },
});
