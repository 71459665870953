import Vue from 'vue';
import Vuex from 'vuex';

import config from '../config/firebase.config';
import * as Firebase from 'firebase/app';
Firebase.initializeApp(config);

import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import uploader from '@/store/modules/uploader';
import whatsappGroups from '@/store/modules/whatsapp-groups';

import { RootState } from './types';

Vue.use(Vuex);

const store = new Vuex.Store<RootState>({
  modules: {
    app,
    auth,
    uploader,
    whatsappGroups,
  },
});

store.dispatch('auth/fetchUser');

export default store;
