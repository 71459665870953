
import Vue from "vue";
import { mask } from 'vue-the-mask'
import { UserProfile } from '@/store/types';

Vue.directive('mask', mask);

export default Vue.extend({
  name: "Login" as string,

  data() {
    return {
      form: false,
      user: { name: "", email: "", phone: "", password: "" },
      confirmPassword: "",
      loading: false,
      rules: {
        required: (value: any) => !!value || 'Campo obrigatório',
        gte6Length: (value: string) => value.length > 6 || 'Deve ter mais de 6 dígitos',
        equalPass: (value: string) => value === this.$data.user.password || 'Senhas não são iguais',
        phone: (value: any) => {
          const pattern = /^\(\d{2}\) \d \d{4}-\d{4}$/
          return pattern.test(value) || 'Número de telefone inválido.'
        }
      },
    };
  },

  watch: {
    form() {
      console.log(this.$data);
    }
  },

  methods: {
    async doRegister(): Promise<void> {
      if (!this.form) {
        return this.$store.dispatch("app/showCustomError", "Formulário Incompleto!")
      }

      this.loading = true;

      const user: UserProfile = {
        avatar: "",
        name: this.user.name,
        email: this.user.email,
        phone: this.user.phone,
        active: true,
        approved: false,
        uid: ""
      }

      await this.$store.dispatch("auth/register", {
        userProfile: user,
        pass: this.user.password
      });

      this.loading = false;
    }
  }
});
