import { Module } from 'vuex';
import { User } from 'firebase';
import firebase from 'firebase';
import {UserProfile} from '@/store/types';

const SELLERS_COLL = process.env.VUE_APP_SELLERS;

const authModule: Module<any, any> = {
  namespaced: true,
  state: {
    user: null,
    loading: true,
  },

  mutations: {
    setUser(state: any, payload: User) {
      state.user = payload;
    },
    setLoading(state: any, payload: boolean) {
      state.loading = payload;
    },
  },

  actions: {
    fetchUser({ commit }) {
      firebase.auth().onAuthStateChanged(async (user) => {

        if (user?.uid) {
          const colRef = firebase.firestore().collection(SELLERS_COLL);
          await colRef
            .doc(user?.uid).get()
            .then((data) => {
              commit('setUser', data.data());
            });
        }

        commit('setLoading', false);
      });
    },

    async register({ commit, dispatch }, payload: { userProfile: UserProfile, pass: string}) {
      const { userProfile, pass } = payload;

      await firebase
        .auth()
        .createUserWithEmailAndPassword(userProfile.email, pass)
        .then(async ({user}) => {

          if (user?.uid) {
            const colRef = firebase.firestore().collection(SELLERS_COLL);
            userProfile.uid = user.uid;

            await colRef.doc(user?.uid).set({
              ...userProfile,
              createdAt: new Date(),
              updatedAt: new Date(),
            });
          }

          dispatch('fetchUser');
        })
        .catch((err: Error) => {
          this.dispatch('app/showCustomError', err.message);
        });
    },

    async login({ commit, dispatch }, payload: any) {
      await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then((user) => {
          dispatch('fetchUser');
        })
        .catch((err: Error) => {
          this.dispatch('app/showCustomError', err.message);
        });
    },

    logout({ commit }) {
      commit('setUser', null);
      firebase.auth().signOut();
    },
  },

  getters: {
    getUser: (state: any): UserProfile => state.user,
    getUid: (state: any): string => state.user.uid,
    getLoading: (state: any): boolean => state.loading,
  },
};

export default authModule;
