import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '*',
    redirect: '/home',
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Página Inicial',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/groups',
    name: 'WhatsAppGroups',
    component: () => import('../views/WhatsAppGroups/WhatsAppGroups.vue'),
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: () => import('../views/Profile.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (!localStorage.getItem('token')) {
//       next('/login')
//       return
//     }
//   }
//   next()
// })

export default router;
